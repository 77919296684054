.custom-outline {
    border: 1px solid #ddd; /* Light gray outline */
    border-radius: 8px; /* Rounded corners */
    transition: border-color 0.3s ease; /* Smooth transition for hover */
  }
  
  .custom-outline:hover {
    border-color: #007bff; /* Change to blue on hover */
  }
  .text-break {
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    text-align: center;
  }
  