/* Default theme (light mode) */
:root {
    --background-color: #ffffff;
    --text-color: #000000;
    --button-bg-color: #007bff;
    --button-text-color: #ffffff;
  }
  
  /* Dark theme */
  [data-theme="dark"] {
    --background-color: #121212;
    --text-color: #ffffff;
    --button-bg-color: #1e90ff;
    --button-text-color: #ffffff;
  }
  
  /* Apply the CSS variables */
  body {
    background-color: var(--background-color);
    color: var(--text-color);
    transition: background-color 0.3s, color 0.3s;
  }
  
  .App-header {
    padding: 20px;
    text-align: center;
  }
  
  .toggle-btn {
    background-color: var(--button-bg-color);
    color: var(--button-text-color);
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .toggle-btn:hover {
    background-color: darken(var(--button-bg-color), 10%);
  }
  
  .alert-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
}

.alert-content {
    display: flex;
    align-items: center;
    padding: 20px;
    background: linear-gradient(135deg, #ffe6e6, #ffe0b3);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    text-align: left;
}

.alert-content i {
    font-size: 2rem;
    color: #ff6b6b;
    margin-right: 15px;
}

.alert-message p {
    margin: 0;
    font-size: 1rem;
    color: #333;
}

/* In your CSS file (e.g., styles.css) */

/* Default light theme */
body.light {
  background-color: #f8f9fa;
  color: #212529;
}

body.light .card {
  background-color: #ffffff;
  color: #212529;
}

body.light .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

/* Dark theme */
body.dark {
  background-color: #121212;
  color: #f8f9fa;
}

body.dark .card {
  background-color: #1f1f1f;
  color: #f8f9fa;
}

body.dark .btn-primary {
  background-color: #1a73e8;
  border-color: #1a73e8;
}

body.dark .text-secondary {
  color: #b0b0b0 !important;
}
/* Darken text color */
.card-text {
  color: #333; /* Darker color for better contrast */
  font-weight: 500; /* Slightly bolder text can improve readability */
}

/* Specific styling for subtitles or descriptions */
.description-text {
  color: #555; /* Adjust this to ensure it’s readable */
  opacity: 0.9; /* Reduce transparency if opacity is being used */
}

/* Add subtle shadow (optional) */
.text-shadow {
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1); /* Light shadow for better readability */
}
body.light-mode {
  background-color: #f8f9fa; /* Light background */
  color: #212529; /* Dark text color for readability */
}

body.light-mode .card {
  background-color: #ffffff;
  color: #212529;
}

/* Dark Mode Styles */
body.dark-mode {
  background-color: #121212; /* Dark background */
  color: #f8f9fa; /* Light text color for readability */
}

body.dark-mode .card {
  background-color: #1f1f1f;
  color: #f8f9fa;
}

/* Specific styling for faded or less visible text in dark mode */
body.dark-mode .card-text {
  color: #b0b0b0 !important; /* Slightly lighter text */
}

body.dark-mode .highlight-text {
  color: #ffffff; /* Ensures highlighted text is fully visible */
}
/* Dark Mode Styles */
body.dark-mode select {
  background-color: #333; /* Lighten background for contrast */
  color: #f8f9fa; /* Lighten text for readability */
  border: 1px solid #555; /* Distinct border color */
}

/* Optional: Adjust focus styling for better interaction feedback */
body.dark-mode select:focus {
  border-color: #1a73e8; /* Highlighted border color on focus */
  outline: none;
  box-shadow: 0 0 4px rgba(26, 115, 232, 0.6); /* Slight glow effect */
}

/* Label Styles for Better Visibility */
body.dark-mode label {
  color: #b0b0b0; /* Lighten label text color */
}
/* Light Mode (default) */


.cs_input_label {
  color: #495057;
  font-weight: 600;
}

/* Dark Mode */
body.dark-mode .cs_form_field {
  background-color: #333; /* Slightly lighter background */
  color: #f8f9fa; /* Lighter text color */
  border: 1px solid #555; /* Clearer border */
}

body.dark-mode .cs_input_label {
  color: #b0b0b0; /* Lighter label color for readability */
}

body.dark-mode .cs_heading_color {
  color: #b0b0b0; /* Adjust heading color */
}

/* Focus styles for both modes */
.cs_form_field:focus {
  border-color: #1a73e8; /* Highlighted border on focus */
  outline: none;
  box-shadow: 0 0 4px rgba(26, 115, 232, 0.6); /* Subtle glow effect */
}

/* Error styling */
.cs_form_field.error {
  border-color: #dc3545;
  color: #dc3545;
}

.error-message {
  color: #dc3545;
  font-size: 0.85rem;
}
/* Light Mode Card */
.card-light {
  background-color: #ffffff;
  color: #212529;
  border: 1px solid #ced4da;
  padding: 20px;
  border-radius: 10px;
}

/* Dark Mode Card */
body.dark-mode .card-dark {
  background-color: #333;
  color: #f8f9fa;
  border: 1px solid #555;
}

/* Text color adjustment */
body.dark-mode .card-dark h5,
body.dark-mode .card-dark p {
  color: #e0e0e0; /* Light text for better contrast */
}

/* Icon styling */
.card-icon {
  color: #007bff; /* Primary color */
}

body.dark-mode .card-icon {
  color: #1a73e8; /* Adjust for dark mode */
}
/* Light Mode Styles */
.cs_hero_info_col .cs_hero_info_title,
.cs_hero_info_col .cs_hero_info_subtitle,
.cs_hero_subtitle {
  color: #212529;
}

/* Dark Mode Styles */
body.dark-mode .cs_hero_info_wrap {
  background-color: #333; /* Darker background for dark mode */
  border: 1px solid #555; /* Add subtle border for separation */
}

body.dark-mode .cs_hero_info_col .cs_hero_info_title,
body.dark-mode .cs_hero_info_col .cs_hero_info_subtitle,
body.dark-mode .cs_hero_subtitle {
  color: #f8f9fa; /* Light text color for better contrast */
}

body.dark-mode .cs_hero_title {
  color: #ffffff; /* Ensure main title is visible */
}

/* Button styles */
body.dark-mode .cs_btn.cs_style_1 {
  background-color: #1a73e8;
  color: #ffffff;
  border: none;
}

body.dark-mode .cs_hero_info_icon {
  background-color: #1a73e8; /* Icon background for contrast */
}
/* Dark mode button */
.btn-dark-mode {
  background-color: #1a73e8;
  color: #ffffff;
  border: none;
}

/* Dark background for dark mode */
.cs_dark_bg {
  background-color: #333;
  color: #f8f9fa;
}
.cs_appointment_form .form-control,
.cs_appointment_form .form-select {
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

.cs_appointment_form .form-control:hover,
.cs_appointment_form .form-select:hover {
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
}

.btn-gradient {
  background: linear-gradient(135deg, #007bff, #6610f2);
  color: #ffffff;
  border: none;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.btn-gradient:hover {
  background: linear-gradient(135deg, #6610f2, #007bff);
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.4);
}

.form-label {
  font-size: 0.9rem;
}

.form-control::placeholder,
.form-select::placeholder {
  color: #6c757d;
}

.form-control,
.form-select {
  border: 1px solid #444;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.text-secondary {
  color: #adb5bd !important;
}
.card {
  border-radius: 15px;
}
.card-header {
  font-weight: 600;
  text-transform: uppercase;
}
.table th, .table td {
  vertical-align: middle;
  white-space: nowrap;
}
.table-responsive {
  overflow-x: auto;
}
.btn-info {
  background-color: #007bff !important; /* Bright blue color */
  border: none !important;
  color: #ffffff !important; /* White text for contrast */
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 123, 255, 0.4);
  transition: all 0.3s ease-in-out;
}

.btn-info:hover {
  background-color: #0056b3 !important; /* Darker blue on hover */
  box-shadow: 0 6px 8px rgba(0, 123, 255, 0.6);
  transform: translateY(-2px); /* Subtle hover animation */
}
